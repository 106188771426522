import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse, { escapeHTMLEntities, stripTagsFromText } from '../utils/parse';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Video from '../components/video';
import ArticleLink from '../components/article-link';
import createSlugFromText from '../utils/slug';

import './post.scss';

const DEFAULT_COLOR = [50, 50, 50];

const generateGradient = ({ rgb }: {rgb: number[]}) => `linear-gradient(
  to bottom, rgba(${rgb.join(',')},.3), #fff)`;

const Post = ({ data, pageContext: { palette } }: any) => {
  const {
    post: {
      title, excerpt, date, dateGmt, content, featuredImage, featuredVideo,
      author: { node: { name: authorName, description: authorDescription } },
      seoFields,
      categories: { nodes: categories },
    },
    previous, next,
  } = data;

  const customSeoDescription = seoFields?.customSeoDescription;
  const customSeoTitle = seoFields?.customSeoTitle;

  const videoUrl = featuredVideo?.featuredVideo;
  const isVideoArticle = !!videoUrl;

  const image = getImage(featuredImage?.node?.localFile);
  const description = featuredImage?.node?.description || '';

  const { Vibrant: vibrantColor, LightVibrant: lightVibrantColor } = palette;
  const topColor = vibrantColor || lightVibrantColor || { rgb: DEFAULT_COLOR };

  return (
    <Layout
      scheme={isVideoArticle ? 'dark' : 'light'}
      style={!isVideoArticle ? { backgroundImage: generateGradient(topColor) } : null}
      categories={categories.map(({ name }: {name: string}) => name)}
    >
      <SEO
        title={customSeoTitle || title}
        metaTitle={customSeoTitle || title}
        description={customSeoDescription || stripTagsFromText(escapeHTMLEntities(excerpt))}
        image={image?.images.fallback.src}
      />
      <article className="article">
        <div className="article-inner">
          <div className="article-wrapper">
            <h2 className="article-title" dir="auto">{title}</h2>
          </div>
          {videoUrl ? (
            <div className="article-featured-video">
              <Video videoUrl={videoUrl} />
            </div>
          ) : (
            <div className="article-featured-image">
              <GatsbyImage image={image} alt={featuredImage?.node?.altText || ''} />
              <div className="article-featured-image-caption">
                {escapeHTMLEntities(description)}
              </div>
            </div>
          )}
          <div className="article-wrapper">
            <div className="article-details">
              <div className="article-date">
                <time dateTime={dateGmt}>{date}</time>
              </div>
              <div className="article-author">
                {authorDescription ? (
                  <Link to={`/authors/${createSlugFromText(authorName)}`}>
                    {authorName}
                  </Link>
                ) : authorName}

              </div>
            </div>
            <div className="article-intro" dir="auto">{escapeHTMLEntities(excerpt)}</div>
            <div className="article-content">{parse(content)}</div>
            { previous || next ? (
              <h3 className="article-related-items-header">Explore more</h3>
            ) : ''}
            <div className="article-related-items">
              { previous ? (
                <ArticleLink
                  title={previous.title}
                  imageData={previous.featuredImage?.node?.localFile}
                  slug={previous.slug}
                  date={previous.date}
                  key={previous.slug}
                />
              ) : '' }
              { next && next.slug !== previous?.slug ? (
                <ArticleLink
                  title={next.title}
                  imageData={next.featuredImage?.node?.localFile}
                  slug={next.slug}
                  date={next.date}
                  key={next.slug}
                />
              ) : '' }
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Post;

export const postQuery = graphql`
query BlogPostById(
  $id: String!
  $previousPostId: String
  $nextPostId: String
) {
  # selecting the current post by id
  post: wpPost(id: { eq: $id }) {
    id
    excerpt
    content
    categories {
      nodes {
        name
      }
    }
    title
    date(formatString: "DD.MM.YY")
    dateGmt
    slug
    author {
      node {
        name
        description
      }
    }

    featuredImage {
      node {
        altText
        description
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1390
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              aspectRatio: 1.7375
            )
          }
        }
      }
    }

    featuredVideo {
      featuredVideo
    }

    seoFields {
      customSeoDescription
      customSeoTitle
    }
  }
  previous: wpPost(id: { eq: $previousPostId }) {
    title
    date(formatString: "DD.MM.YY")
    slug
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 314
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              aspectRatio: 1.7375
            )
          }
        }
      }
    }
  }
  # this gets us the next post by id (if it exists)
  next: wpPost(id: { eq: $nextPostId }) {
    title
    date(formatString: "DD.MM.YY")
    slug
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 314
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              aspectRatio: 1.7375
            )
          }
        }
      }
    }
  }
}
`;
