import React from 'react';
import ReactPlayer from 'react-player';
import './video.scss';

const Video = ({ videoUrl }: { videoUrl: string }) => (
  <div className="video-player">
    <div className="video-player-inner">
      <ReactPlayer
        controls
        playing={false}
        url={videoUrl}
        width="100%"
        height="100%"
      />
    </div>
  </div>
);

export default Video;
